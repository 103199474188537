<template>
  <div class="world powderlist" id="wwSearchPowders">
    <div class="wrapper-title">
      <div class="container">
        <router-link
          :to="{ name: 'ww.dashboard' }"
          class="text-wilmsLightBlue font-100 block"
          >&larr; {{ $t("Back to overview") }}</router-link
        >
        <div class="flex items-center justify-between sm:flex-wrap">
          <h1>{{ $t("Powderlist") }}</h1>
          <input
            type="text"
            class="
              search
              shadow-xl
              py-4
              p-2
              leading-none
              pl-10
              rounded-sm
              text-wilmsGrey
              bg-white
            "
            :placeholder="$t('Search')"
          />
        </div>
      </div>
    </div>

    <div class="powderlist-wrapper">
      <div class="container">
        <div class="shadow-lg rounded-sm bg-white p-2 mt-6 mb-10">
          <div
            class="
              flex
              bg-wilmsLightGrey
              py-3
              rounded-sm
              text-wilmsBlue
              font-700
            "
          >
            <div class="w-2/6 px-5">
              <span>{{ $t("Description") }}</span>
            </div>
            <div class="w-2/6 px-5">
              <span>{{ $t("Bestelnr") }}</span>
            </div>
            <div class="w-1/6 px-5 text-center">
              <span>{{ $t("Powder coating") }}</span>
            </div>
            <div class="w-1/6 px-5 text-center">
              <span>{{ $t("Wetpainting") }}</span>
            </div>
          </div>
          <div class="body list">
            <div
              v-for="(poeder, index) in poederLijst"
              :key="index"
              class="flex"
            >
              <div class="description">{{ poeder.Omschrijving }}</div>
              <div class="code">{{ poeder.Leverancierscode }}</div>
              <div class="coating">
                {{ interpolatePoederBoolean(poeder["Poederlak?"]) }}
              </div>
              <div class="wet">
                {{ interpolatePoederBoolean(poeder["Natlak?"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import List from 'list.js'

import poederLijst from '@/assets/poederLijst.json'

export default {
  mounted () {
    List('wwSearchPowders', {
      valueNames: ['description', 'code']
    })
  },
  computed: {
    poederLijst () {
      return poederLijst
    }
  },
  methods: {
    interpolatePoederBoolean (poederBoolean) {
      return poederBoolean === 'Ja' ? 'x' : '-'
    }
  }
}
</script>
